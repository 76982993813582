﻿angular.module('transactionsApp')
  .service('ReferenceDetailsService', ['$fancyModal', function($fancyModal) {
    this.openModal = function(request, response, payapiid) {
      var modalInstance = $fancyModal.open({
        template: `
          <div>
            <ul class="nav nav-tabs" id="myTabs">
              <li class="active"><a data-toggle="tab" href="#request">Request</a></li>
              <li><a data-toggle="tab" href="#response">Response</a></li>
              <li><a data-toggle="tab" href="#payapiid">Pay API ID</a></li>
            </ul>

            <div class="tab-content">
              <div id="request" class="tab-pane fade in active">
                <h1>Request</h1>
                <xmp>${request}</xmp>
              </div>
              <div id="response" class="tab-pane fade">
                <h2>Response</h2>
                <xmp>${response}</xmp>
              </div>
              <div id="payapiid" class="tab-pane fade">
                <h2>Pay API ID</h2>
                <code>${payapiid}</code>
              </div>
            </div>
          </div>
        `,
        controller: ['$scope', function($scope) {
          $scope.request = request;
          $scope.response = response;
        }]
      });

      return modalInstance;
    };
  }]);
