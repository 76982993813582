﻿class ChangeOrganizationService {
  constructor($http, $rootScope, $window, CurrencyToggleService) {
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.CurrencyToggleService = CurrencyToggleService;
  }

  submitFormChangeOrganization($scope) {
    $scope.$applyAsync(() => {
      const selected = this.parseSelectedOrganization($scope.selectedOrganization);
      if (!selected) return;

      this.setRequestHeaders($scope);

      this.$http.post('/Account/ChangeOrganization', { Organization: selected.id }, { headers: $scope.headers })
        .then(response => {
          this.handleSuccess(selected);
          this.$window.location.reload(); // Move the reload call inside the success handler
        })
        .catch(error => this.handleError(error));
    });
  }

  parseSelectedOrganization(selectedOrganization) {
    try {
      return JSON.parse(selectedOrganization);
    } catch (error) {
      console.error('Error parsing selected organization:', error);
      return null;
    }
  }

  setRequestHeaders($scope) {
    const tokenVerification = $("input[name=__RequestVerificationToken]").val() || "";
    if (tokenVerification) {
      $scope.headers["RequestVerificationToken"] = tokenVerification;
    }
    this.$http.defaults.headers.common['X-XSRF-Token'] = $scope.headers["X-XSRF-Token"];
  }

  handleSuccess(selected) {
    const { state, business } = selected;
    localStorage.setItem('currentCountry', this.CurrencyToggleService.checkAbbreviations([state]));
    localStorage.setItem('business', business);
    this.$rootScope.business = business;
    //this.$window.reload();
  }

  handleError(error) {
    console.error('Error changing organization:', error);
  }
}

angular.module('transactionsApp')
  .service('ChangeOrganizationService', ['$http', '$rootScope', '$window', 'CurrencyToggleService', ChangeOrganizationService]);
