
angular.module('transactionsApp').service('TabService', [function () {
    var service = {};
    service.showCredit = 'No';
    service.showGift = 'No';
    service.showACH = 'No';

    service.currentOpen = [service.showCredit, service.showGift, service.showACH];

    service.Visibility = {
        Yes: 'Yes',
        No: 'No'
    };

    service.visibilityStates = {
        P: [service.Visibility.Yes, service.Visibility.Yes, service.Visibility.Yes],
        H: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        G: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        I: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        J: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        D: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        B: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        E: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        A: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        F: [service.Visibility.Yes, service.Visibility.No, service.Visibility.No],
        Q: [service.Visibility.No, service.Visibility.Yes, service.Visibility.No],
        K: [service.Visibility.No, service.Visibility.No, service.Visibility.Yes],
        M: [service.Visibility.No, service.Visibility.No, service.Visibility.No],

    }

    service.getVisibilityStates = function () {
        return service.visibilityStates;
    };

    const assignVisibilityBasedOnMid = () => {
        service.showCredit = service.currentOpen[0];
        service.showGift = service.currentOpen[1];
        service.showACH = service.currentOpen[2];
    }

    service.sortMid = (mid) => {
        // Check that visibilityStates has dictionary entry for mid's last character
        if (!mid || typeof mid !== 'string' || !service.visibilityStates[mid.slice(-1)]) {
            assignVisibilityBasedOnMid();
            return service.currentOpen;
        };

        // Return the visibility state for the last character of the mid and save as the current open tabs
        service.currentOpen = service.visibilityStates[mid.slice(-1)];
        assignVisibilityBasedOnMid();
        return service.currentOpen;        
    }

    service.openTab = function (event, tabname) {

        if(!tabname || typeof tabname !== 'string') {

            return;
        }

        if (tabname === 'Credit' && service.currentOpen[0] === service.Visibility.Yes) {
            service.showCredit = 'Yes';
        } else if (tabname === 'Gift' && service.currentOpen[1] === service.Visibility.Yes) {
            service.showGift = 'Yes';
        } else if (tabname === 'ACH' && service.currentOpen[2] === service.Visibility.Yes) {
            service.showACH = 'Yes';
        }
    };


    service.openDefaultTab = function () {

        if (service.showCredit === 'Yes') {
            service.openTab(null, 'Credit');
        } else if (service.showGift === 'Yes') {
            service.openTab(null, 'Gift');
        } else if (service.showACH === 'Yes') {
            service.openTab(null, 'ACH');
        } 
    };
    return service;
    
}]);
