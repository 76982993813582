﻿class AggregateListService {
  constructor($rootScope, $currencyToggleService) {
    this.$rootScope = $rootScope;
    this.CurrencyToggleService = $currencyToggleService;
  }

  matchesFilter(filterKey, currentValue, filters) {
    if (!filters[filterKey]) return true;
    if (typeof currentValue === 'string') {
      return currentValue.includes(filters[filterKey]);
    }
    if (typeof currentValue === 'number') {
      return currentValue.toFixed(2).includes(filters[filterKey]);
    }
    return false;
  }

  isItemSelected(curItem, filters) {
    const fieldsToCheck = ['mid', 'last4', 'invoice', 'batch', 'amount', 'refNo', 'status', 'payApiId', 'acctNo'];

    const validateAndLog = (field, curValue, transform = (v) => v) => {
      let curField = transform(curValue);
      if (curField == undefined || curField === '') {
        curField = 'not found';
      }

      const stringCurField = String(curField);
      const stringFiltersField = String(filters[field]);
      const recordField = stringFiltersField + "ByRecordNo";

      if (
        filters[field] !== '' &&
        filters[field] != undefined &&
        curField !== 'not found' &&
        stringCurField !== stringFiltersField &&
        recordField !== stringCurField
      ) {
        return false;
      }
      return true;
    };

    return fieldsToCheck.every(field => validateAndLog(field, curItem[field]));
  }

  updateSummaries(selectedItem, updatedSummaries, filters) {
    if (filters.showNonCaptured || selectedItem.captured) {
      const key = moment(selectedItem.dateTimeLocal).format('YYYY-MM-DD');
      const summary = updatedSummaries[key] || { date: key, count: 0, total: 0 };
      const updatedCount = summary.count + 1;
      const updatedTotal = selectedItem.captured && selectedItem.amount
        ? summary.total + parseFloat(selectedItem.amount)
        : summary.total;

      return {
        ...updatedSummaries,
        [key]: { ...summary, count: updatedCount, total: updatedTotal },
      };
    }
    return updatedSummaries;
  }

  updateUniqueValues(value, collection) {
    return collection.includes(value) ? collection : [...collection, value];
  }

  updateAggregates(curItem, aggregates, filters, firstLoad) {
    const selectedItem = { ...curItem, selected: false };

    selectedItem.dateTimeLocal = moment(selectedItem.dateTimeLocal).local().format('MM/DD/YYYY hh:mm:ss a');

    let updatedSummariesObj = { ...aggregates.summaries };
    let updatedTotalAmount = aggregates.total;

    updatedSummariesObj = this.updateSummaries(selectedItem, updatedSummariesObj, filters);
    const summaryKey = moment(selectedItem.dateTimeLocal).format('YYYY-MM-DD');

    if (updatedSummariesObj[summaryKey]) {
      updatedTotalAmount = updatedSummariesObj[summaryKey].total;
    }

    const updatedMidsArray = firstLoad ? this.updateUniqueValues(curItem.mid, aggregates.mids) : aggregates.mids;
    const updatedBatchesArray = firstLoad ? this.updateUniqueValues(curItem.batch, aggregates.batches) : aggregates.batches;
    return {
      ...aggregates,
      results: [...aggregates.results, selectedItem],
      summaries: updatedSummariesObj,
      mids: updatedMidsArray,
      batches: updatedBatchesArray,
      count: aggregates.count + 1,
      total: updatedTotalAmount,
    };
  }

  aggregateLists(scope) {
    return (aggregates, curItem) => {
      const filters = scope.filters;
      const firstLoad = scope.firstLoad;

      if (this.isItemSelected(curItem, filters)) {
        return this.updateAggregates(curItem, aggregates, filters, firstLoad);
      }

      return aggregates;
    };
  }
}

angular.module('transactionsApp')
  .service('AggregateListService', ['$rootScope', 'CurrencyToggleService', AggregateListService]);
