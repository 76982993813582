import * as angular from 'angular';

export class VoidReturnController {

  constructor() {
  }
}

angular.module('transactionsApp').component('voidReturn', {
  bindings: {
    buttonText: '@',
    modalId: '@'
  },
  template: `<div>
              <void-return-modal button-text="{{$ctrl.buttonText}}" modal-id="{{$ctrl.modalId}}"></void-return-modal>
            </div>`,
  controller: ['$rootScope', VoidReturnController]
});

