
class VoidReturnModalController {
  static $inject = ['$scope', '$rootScope', '$pay', '$localStorage'];

  constructor($scope, $rootScope, $pay, $localStorage) {
    Object.assign(this, { $scope, $rootScope, $pay, $localStorage });

    $scope.isModalOpen = true;
    $scope.root = $rootScope;
    $scope.showAlert = false;

    $scope.toggleModal = () => {
      $scope.isModalOpen = !$scope.isModalOpen;
      $rootScope.isModalOpen = false;
    }
    $scope.toggleModalWarn = () => {
      $scope.showAlert = true;

    }

    $scope.acceptAlert = (tranType) => {

      $scope.inProgress = true;
      $scope.transactionType = tranType;
      $scope.showAlert = false;

      $scope.authenticationSecret = $localStorage.config().secret;
      for (var row of $rootScope.selectedRows) {

        var payload = $scope.createPayload(row, $scope.transactionType, $scope.authenticationSecret);
        var process = "process" + tranType;

        $pay[process](payload, $scope.successResponse, false)
          .catch((ex) => {

            $rootScope.isModalOpen = false;
            $scope.isModalOpen = false;
          })
          .finally(() => {
            $scope.inProgress = false

          }
          );
      }

      $rootScope.isModalOpen = false;
      $scope.isModalOpen = false;
    }

    $scope.successResponse = (response) =>  {
      $scope.inProgress = false;
      $scope.request = {
        PartialAuth: "allow",
        FrontEndID: "Datacap Virtual Terminal",
      };
      $scope.mode = "Manual";
      response.content.showReceipt = false;
      $rootScope.responses.unshift(response.content);

    };

    $scope.rejectAlert = () => {
      $scope.showAlert = true;
      $scope.isModalOpen = true;
      $rootScope.isModalOpen = false;
    }

    $scope.createPayload = function (row, tranType, authenticationSecret) {
      return {
        Amount: row.amount,
        AccountNumber: row.acctNo,
        CardExpiration: row.expiration,
        TranType: tranType,
        PartialAuth: 'Allow',
        Processor: row.merchantId.slice(-1),
        AuthenticationSecret: authenticationSecret
      }
    }
  }
  
}
angular.module('transactionsApp').component('voidReturnModal', {
  bindings: {
    buttonText: '@',
    modalId: '@'
  },
  template: `
                <!-- Button trigger modal -->

                <button class="btn btn-default" data-toggle="modal" data-target="#{{ $ctrl.modalId }}" tooltip-placement="right" style="padding: 0.1em 0.5em;" ng-click="root.toggleModalMain()">
                  {{ $ctrl.buttonText }}
                </button>

                <!-- Modal -->
                <div class="modal fade" id="{{ $ctrl.modalId }}" tabindex="-1" role="dialog" aria-labelledby="{{ $ctrl.modalId }}" aria-hidden="true" ng-style="{'backdrop-filter':'blur(5px)','top':'25%'}">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="{{ $ctrl.ModalId }}">Would you like to <strong>{{$ctrl.buttonText}}</strong> these transactions?</h5>
                            <div class="alert alert-danger" ng-show="showAlert">
                              <strong>Warning!</strong> Are you sure you want to {{ $ctrl.buttonText }} these transactions? This is not a reversible transaction.
                              <button type="button"  data-dismiss="modal" ng-click="acceptAlert($ctrl.buttonText)">Yes, {{ $ctrl.buttonText }} these transactions</button>
                              <button <button type="button"  data-dismiss="modal" ng-click="rejectAlert()">No, do not {{ $ctrl.buttonText }} these transactions</button>
                            </div>
                          </div>
                          <hr/>

                          <div class="modal-body">
                            <div>
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                        Select
                                    </th>
                                    <th scope="col">Merchant ID</th>
                                    <th scope="col">PayAPI ID</th>
                                    <th scope="col">Trancode</th>
                                    <th scope="col">Reference No</th>
                                    <th scope="col">Invoice No</th>
                                    <th scope="col">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr ng-repeat="row in root.selectedRows">
                                    <td class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" ng-click="toggleSelection(row)" ng-checked="true">
                                    </td>
                                    <td>{{row.merchantId}}</td>
                                    <td>{{row.payApiId}}</td>
                                    <td>{{row.trancode}}</td>
                                    <td>{{row.refNo}}</td>
                                    <td>{{row.invoice}}</td>
                                    <td>{{row.amount}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-default" data-dismiss="modal" ng-click="toggleModal()">Cancel</button>
                              <button type="button"  class="btn btn-danger" ng-click="toggleModalWarn()">{{ $ctrl.buttonText }} These Transactions</button>
                          </div>
                      </div>
                    </div>
                </div>
            `,
  controller: VoidReturnModalController
});
