﻿class TableNameService {
  constructor() {
    this.sortingState = {
      sortBy: ''
    };
  }

  getColumnNames() {
    return [
      'dateTimeLocal',
      'mid',
      'merchantId',
      'payApiId',
      'refNo',
      'trancode',
      'status',
      'cardBrand',
      'acctNo',
      'last4',
      'expiration',
      'batch',
      'invoice',
      'amount',
      'tip',
      'request',
      'response'
    ];
  }

  getColumnDisplayNames() {
    return [
      'Date/Time',
      'Merchant Identifier',
      'Merchant ID',
      'PayAPI ID',
      'Reference No',
      'Tran Code',
      'Status',
      'Card Brand',
      'Account No',
      'Last 4',
      'Expiration',
      'Batch No',
      'Invoice',
      'Amount',
      'Tip',
      'Request',
      'Response'
    ];
  }
}

angular.module('transactionsApp')
  .service('TableNameService', TableNameService);
