angular.module('transactionsApp').service('$selectByMid', ['$http', '$rootScope', function ($http, $rootScope) {
    const service: any = {};
    service.namesToEndings = {
        'TSYS':'A',
        'Galaxy':'Q',
        'Heartland':'D',
        'Mercury':'C',
        'Paymentech':'B',
        'Rapid Connect':'E',
        'First Data': 'E',
        'Worldpay Legacy':'F',
    }

    service.returnsBatchId = {
        'A':true,
        'D':false,
        'E':false,
        'Q':true,
        'C':true,
        'B':true,
        'F':true,
        'P':true
    }
    
    service.returnProcessorFromEnding = (ending) => {

        // Remove the ending if we were passed the whole mid
        ending.length > 1 ? ending = ending.slice(-1) : ending = ending;

        const processor = Object.keys(service.namesToEndings).find(key => service.namesToEndings[key] === ending.toUpperCase());
        return processor ? processor : 'No match found';
    }

    service.returnEndingFromProcessor = (processor) => {
        const ending = service.namesToEndings[processor.toUpperCase()];
        return ending ? ending : 'No match found';
    }

    service.sendsBatchId = (processor) => {

        // We were sent the single letter code
        if(processor.length === 1) {
            return service.returnsBatchId[processor.toUpperCase()] == null ? false : service.returnsBatchId[processor.toUpperCase()];
        }

        // We need to lookup the single letter code from the processor name
        const processorEnding = service.returnEndingFromProcessor(processor);

        if(!processorEnding || processorEnding === 'No match found') return false;

        return service.returnsBatchId[processorEnding.toUpperCase()];
        
    };

    return service;
}]);