﻿angular.module('transactionsApp')
  .service('SelectionToRunTransactionsService', ['$rootScope', function($rootScope) {
    this.initializeSelectionHandler = function($scope) {
      $scope.voidReturnSelected = false;
      $scope.downloading = false;

      const toggleVoidReturnSelection = () => {
        $scope.voidReturnSelected = !$scope.voidReturnSelected;
        $scope.transactions.forEach(row => {
          row.selected = false;
        });
        $scope.selectedRows = $scope.transactions;
      };

      const toggleModal = () => {
        console.log("$rootScope.isModalOpen", $rootScope.isModalOpen)
        $rootScope.isModalOpen = !$rootScope.isModalOpen;
        console.log("$rootScope.isModalOpen", $rootScope.isModalOpen)
      };

      const toggleSelection = (row) => {
        row.selected = !row.selected;
        if (row.selected) {
          $rootScope.selectedRows.push(row);
        } else {
          const index = $rootScope.selectedRows.findIndex(selectedRow => selectedRow === row);
          if (index !== -1) {
            $rootScope.selectedRows.splice(index, 1);
          }
        }
      };

      const toggleDownloading = () => {
        $scope.downloading = !$scope.downloading;
      };

      $scope.changeVoidReturnSelection = () => {

        toggleVoidReturnSelection();
      };

      $scope.toggleSelection = toggleSelection;
      $scope.toggleDownloading = toggleDownloading;

      $rootScope.isModalOpen = false;
      $rootScope.selectedRows = [];
      $rootScope.toggleModalMain = toggleModal;
    };
  }]);
