
class TimeInputController {

  hstep: number;
  mstep: number;
  ismeridian: boolean;
  $http: ng.IHttpService;
  startTime: string;
  syncDate: Date;
  $rootScope: ng.IRootScopeService;


  constructor(uibTimepickerConfig: any, $http: any, $rootScope: any) {

    this.hstep = 1;
    this.mstep = 15;
    this.ismeridian = true;
    this.$http = $http;




    // Configure the uib-timepicker directive
    uibTimepickerConfig.showMeridian = this.ismeridian;
    uibTimepickerConfig.hourStep = this.hstep;
    uibTimepickerConfig.minuteStep = this.mstep;
    uibTimepickerConfig.mousewheel = true;
    this.$rootScope = $rootScope;
    this.syncDate = $rootScope.syncDate;
    this.startTime = $rootScope.startTime;
  }
}

angular.module('transactionsApp').component('timeInput', {
  bindings: {
    time: '=',
    setStartTime: '&'
  },
  template: `
    <div class="text-center">
      <div class="col-md-4 m-r-15"></div>
      <div uib-timepicker ng-model="$root.startTime" ng-change="$root.submitTime($root.startTime)" hour-step="$ctrl.hstep" minute-step="$ctrl.mstep" mousewheel=true></div>
    </div>
  `,
  controller: ['uibTimepickerConfig', '$http', '$rootScope', TimeInputController]
});
