angular.module('transactionsApp').service('$pageSetupService', [function () {
  var service = {
    getCols: getCols,
    getColNames: getColNames,
    sort: sort,
    sortClass: sortClass,
    setupDateOptions: setupDateOptions,
    getBrands: getBrands,
    getTranCodes: getTranCodes
  };


  function getCols() {
    return function () {
      [
        'dateTimeLocal',
        'mid',
        'merchantId',
        'payApiId',
        'refNo',
        'trancode',
        'status',
        'cardBrand',
        'acctNo',
        'last4',
        'expiration',
        'batch',
        'invoice',
        'amount',
        'tip',
        'request',
        'response'
      ];
    }
  };

  function getColNames() {
    return function () {
      [
        'Date/Time',
        'Merchant Identifier',
        'Merchant ID',
        'PayAPI ID',
        'Reference No',
        'Tran Code',
        'Status',
        'Card Brand',
        'Account No',
        'Last 4',
        'Expiration',
        'Batch No',
        'Invoice',
        'Amount',
        'Tip',
        'Request',
        'Response'
      ];
    }
  };

  function sort(val, sortBy) {
    var prefix = '';
    if (val === sortBy) {
      prefix = '-';
    }
    return prefix + val;
  };

  function setupDateOptions(startDate, endDate) {

    return {
      startDate: startDate,
      endDate: endDate,
      timePicker: true,
      timePickerSeconds: true,
      alwaysShowCalendars: true,
      ranges: {
        'Today': [moment().startOf('day'), moment().endOf('day')],
        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
        'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
        'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month')],
        'Last Year': [moment().subtract(1, 'year').startOf('year').startOf('month').startOf('day'), moment().subtract(1, 'year').endOf('year')]
      },
      showCustomRangeLabel: true,
      autoApply: true,
      locale: {
        format: 'MM/DD/YYYY hh:mm:ss a z'
      }
    };
  };
  function getTranCodes(){
    return [
      "Adjust",
      "AdjustByRecordNo",
      "Authorize",
      "BatchClose",
      "BatchSummary",
      "CardLookup",
      "CardLookupByRecordNo",
      "DCAppleToken",
      "DCGoogleToken",
      "EMVAdjust",
      "EMVPreAuth",
      "EMVReturn",
      "EMVSale",
      "EMVVoidSale",
      "EMVZeroAuth",
      "IncrementalAuthByRecordNo",
      "PartialReversalByRecordNo",
      "PreAuth",
      "PreAuthByRecordNo",
      "PreAuthCapture",
      "PreAuthCaptureByRecordNo",
      "Return",
      "ReturnByRecordNo",
      "Sale",
      "SaleByRecordNo",
      "VoidByInvoiceNo",
      "VoidReturnByRecordNo",
      "VoidSale",
      "VoidSaleByRecordNo",
      "ZeroAuth",
      "ZeroAuthByRecordNo"
    ]

  }

  function getBrands() {
    return [
      "M/C",
      "VISA",
      "AMEX",
      "DCVR",
      "Debit",
      "DEBIT",
      "Foodstamp",
      "UNDETERMINED",
      "EWIC",
      "OTHER",
      "Cash",
      "WEX",
      "VOYAGER",
      "CUP",
      "FLEETCOR"
    ];
  }

  function sortClass(col, sortBy) {

    if (!col || !sortBy) return 'fa-sort';
    if (col === sortBy) {
      return 'fa-sort-asc';
    } else if ('-' + col === sortBy) {
      return 'fa-sort-desc';
    }
    return 'fa-sort';
  };

  return service;
}]);


