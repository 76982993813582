import * as angular from 'angular';

class ModalAlertController {


  constructor() {
    
  }

  close() {
    console.log('Alert closed');
  }
}

angular.module('transactionsApp').component('modalAlert', {
  bindings: {
    type: '@',
    dismissible: '<',
    close: '&',
    titleMessage: '<',
    message2: '<',
    message3: '<',
  },
  transclude: true,
  template: `
    <div class="text-left alert alert-warning" role="alert" uib-alert ng-class="'alert-' + (alert.type || 'warning')  ">
      <div><strong>{{$ctrl.titleMessage}}</strong></div>
      <br/>
      <div>{{$ctrl.message2}}</div>
      <div>{{$ctrl.message3}}</div>
    </div>
  `,
  controller: ModalAlertController
});