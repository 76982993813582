angular.module('transactionsApp').component('splashWarning', {
    bindings: {
      title: '<',
      message: '<'
    },
    template: `
      <div class="alert alert-primary" role="alert">
        <div>
          <p><strong>{{$ctrl.title}}</strong></p>
        </div>
        <div>
          {{$ctrl.message}}
        </div>
      </div>
    `
  });