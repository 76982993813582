'use strict';
import moment from "moment-timezone";

var transactionsApp = angular.module('transactionsApp',
  [
    'angularjs.daterangepicker',
    'angularUtils.directives.dirPagination',
    'vesparny.fancyModal',
    'ngSanitize',
    "virtualTerminalApp",
    "reportApp",
    'ngCookies',
    'ngRoute',
    'ui.bootstrap',
  ]).controller('transactionsCtrl', ['$scope', '$rootScope', '$fancyModal', '$creditTransactions',
    'CurrencyToggleService', '$http', '$cookies', "$localStorage", "$window", "$route",
    'ChangeOrganizationService','AggregateListService','TableNameService', 'SelectionToRunTransactionsService',
    'ReferenceDetailsService', '$timeout', '$filterService', '$loadDataService', '$pageSetupService',
    function ($scope, $rootScope, $fancyModal, $creditTransactions, CurrencyToggleService, $http, $cookies,
              $localStorage, $window, $route, ChangeOrganizationService, AggregateListService,
              TableNameService, SelectionToRunTransactionsService,ReferenceDetailsService, $timeout,
              $filterService, $loadDataService, $pageSetupService)
    {
      initializeTransactionsApp($scope, $rootScope, $cookies, $localStorage, $pageSetupService, $filterService);

      $scope.submitForm = () => {
        ChangeOrganizationService.submitFormChangeOrganization($scope);

      };


      $scope.clearAll = function () {
        Object.assign($scope, $filterService.clearAll()); // Update $scope with cleared data
      };




     }
  ]);

function initializeTransactionsApp($scope, $rootScope, $cookies, $localStorage, $pageSetupService, $filterService) {
  // Initialize scope variables
  $scope.rawResults = {};
  $scope.transactions = [];
  $scope.inProgress = false;
  $scope.startDate = new Date(moment().startOf('day').add(-1, 'days'));
  $scope.endDate = new Date(moment().endOf('day').add(-1, 'days'));

  $scope.filters = {};
  $scope.details = null;
  $scope.showTerminal = true;

  // Initialize sorting
  $scope.sortBy = '-datetimeLocal';

  // Initialize selection
  $scope.showNonCaptured = false;
  $scope.voidReturnSelected = false;
  $rootScope.selectedRows = [];

  // Initialize messages
  $scope.successMsg = null;
  $scope.errorMsg = null;

  // Setup headers and CORS
  setupCorsConfiguration($scope, $cookies, $localStorage);

  $scope.terminalWording = 'Close'
  $scope.summaryWording = 'Close'

  $scope.parameters = {}
  $scope.parameters.filterField = [];
  $scope.parameters.filterList = [];

  $scope.pageNum = 0;
  $scope.pageSize = 25;
  Object.assign($scope, $filterService.init());

}

function setupCorsConfiguration($scope, $cookies, $localStorage) {
  // Needed for CORS - the cookies may or may not be strictly required as cookies has vulnerabilities but this is working for now
  var antiForgeryToken = $cookies.get('X-XSRF-TOKEN');
  let tokenVerification = $("input[name=__RequestVerificationToken]").val() || "";

  // Needed for CORS policies
  $scope.headers = {
    'Authorization': $localStorage.config().secret,
    'Content-Type': 'application/json',
    'User-Agent': 'Datacap Virtual Terminal',
    'X-XSRF-Token': angular.element('input[name="__RequestVerificationToken"]').attr('value') == undefined
      ? ''
      : angular.element('input[name="__RequestVerificationToken"]').attr('value'),
    "RequestVerificationToken": tokenVerification
  };
}


