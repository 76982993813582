
angular.module('transactionsApp').service('$creditTransactions', ['$rootScope', '$http', '$location', '$localStorage',
  function ($rootScope, $http, $location, $localStorage) {
  this.validMethods = ['GET', 'POST', 'PUT', 'DELETE']
  this.buildRequest = function (method, url, startDate, endDate, continuationToken, pageNum, pageSize, filters, field, includeNotCaptured, orderby, direction) {

    if (this.validMethods.includes(method) === false) {
      throw new Error('Invalid method: ' + method);
    };


    buildAndValidateUrl();

    var request = {
      url: url,
      method: method,

      headers: {
        'Content-Type': 'application/json'
      }
    };

    var filters = filters || [];

    var postRequest = {
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json;odata=verbose',
        'x-ms-continuationtoken': continuationToken
      },
      data: {
        Offset: String(pageNum > 1 ? (((pageNum - 1) * pageSize)) : 0),
        Limit: String(pageSize),
        StartDate: new Date(startDate),
        EndDate: new Date(endDate),
        ExcludedTranCodes: [],
        filters: filters,
        filterField: field,
        includeNotCaptured: includeNotCaptured,
        OrderBy: orderby,
        Direction: direction
      }
    }

    return method === 'GET' ? request : postRequest;

    function buildAndValidateUrl() {
      var port = $location.port() === 80 ? "" : ":" + $location.port();
      url = $location.protocol() + "://" + $location.host() + port + url;
      trial_url = url;
      try {
        new URL(trial_url);
      } catch (_) {
        throw new Error('Invalid URL: ' + url);
      }
    }
  };

  function transformContent(data) {

    if (!data && (!data.content || !data.ModelState)) {

      return { 'data': {} };

    }


    var transformedContent = [];
    var transformedData = {};
    var errors = {};

    ensureUpperCaseFormat();
    loadModelStateIntoErrors();

    return {
      content: transformedContent,
      errors: errors,
      data: transformedData
    };

    function loadModelStateIntoErrors() {
      if (data.ModelState) {
        for (var field in data.ModelState) {
          if (field.startsWith('Model.')) {
            var errorField = field.substring('Model.'.length);
            errors[errorField] = data.ModelState[field];
          } else if (field.startsWith('model.')) {
            var errorField = field.substring('model.'.length);
            errors[errorField] = data.ModelState[field];
          }
        }
      }
      return field;
    }

    function ensureUpperCaseFormat() {
      if (data.content) {

        for (var field in data.content) {
          transformedContent.push(data.content[field]);
        }

      }
      if (data) {
        for (var field in data) {
          if (field[0] !== field[0].toUpperCase()) {
            transformedData[field[0].toUpperCase() + field.slice(1)] = data[field];
          } else {
            transformedData[field] = data[field];
          }
        }
      }
      return field;
    }
  }


   this.buildResponseHandler = function (callback, wasSuccessful) {

    if (typeof callback !== 'function') {
      throw new Error('Invalid callback: ' + callback);
    }
    if (typeof wasSuccessful !== 'boolean') {
      throw new Error('Invalid wasSuccessful: ' + wasSuccessful);
     }

     return function (response) {
       if (response == undefined) { throw new Error("No response") }

      var transformedData = transformContent(response.data);


      var result = {
        wasSuccessful: wasSuccessful || false,
        content: Object.values(transformedData.data) || [],
        Status: transformedData.data ? transformedData.data.Status : 'ERROR',
        Account: transformedData.data ? transformedData.data.Account : 'NoAccount',
        Message: transformedData.data ? transformedData.data.Message : 'Server Error. Please try again.',
        errors: transformedData.errors || {},
      };

      if (callback) {
        return callback(result);
      }

    };
  };


  this.getAllTrancodes = function (parameters, callback) {
    var start = parameters.startDate || '';
    var end = parameters.endDate || '';
    var continuationToken = parameters.continuationToken || '';
    var pageNum = parameters.pageNum || 0;
    var pageSize = parameters.pageSize || 0;
    var filter = parameters.filters || '';
    var field = parameters.filterField || '';
    var includeNotCaptured = parameters.includeNotCaptured || true;
    var req = this.buildRequest('POST', `/Transactions/TranCodes`, start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured);
    return $http(req).then(this.buildResponseHandler(callback, true),
      this.buildResponseHandler(callback, false));
    }

  this.getAllBrands = function (parameters, callback) {
    var { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction } = PrepareParameters(parameters);

    var req = this.buildRequest('POST', `/Transactions/Brands`, start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured);
    return $http(req).then(this.buildResponseHandler(callback, true),
      this.buildResponseHandler(callback, false));
  }

  this.getAllBatchNumbers = function (parameters, callback) {
    var { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction } = PrepareParameters(parameters);

    var req = this.buildRequest('POST', `/Transactions/BatchNumbers`, start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured);
    return $http(req).then(this.buildResponseHandler(callback, true),
      this.buildResponseHandler(callback, false));
  }

  this.getFilteredTransactionsLookup = function (parameters, callback) {

    var { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction } = PrepareParameters(parameters);

    var req = this.buildRequest('POST', `/Transactions/FilterBy`, start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction);

    return $http(req).then(
      this.buildResponseHandler(callback, true),
      this.buildResponseHandler(callback, false));
  }
  this.getCount = function (parameters, callback) {

    var { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction } = PrepareParameters(parameters);

    var req = this.buildRequest('POST', `/Transactions/Count`, start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured);
    return $http(req).then(this.buildResponseHandler(callback, true),
      this.buildResponseHandler(callback, false));
  }

  this.getSum = function (parameters, callback) {

    var { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction } = PrepareParameters(parameters);

    var req = this.buildRequest('POST', `/Transactions/Sum`, start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured);

    return $http(req).then(this.buildResponseHandler(callback, true),
      this.buildResponseHandler(callback, false));
  }
  this.getTransactionsLookup = function (parameters, callback) {

      var { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction } = PrepareParameters(parameters);

      var req = this.buildRequest('POST', `/Transactions`, start, end, continuationToken, pageNum, pageSize);

      return $http(req).then(this.buildResponseHandler(callback, true),
        this.buildResponseHandler(callback, false));
    };

}]);

function PrepareParameters(parameters) {


    var start = parameters.startDate || new Date();
    var end = parameters.endDate || new Date();

    var continuationToken = parameters.continuationToken || '';
    var pageNum = parameters.pageNum || 0;
    var pageSize = parameters.pageSize || 0;
    var filter = parameters.filters || [];
    var field = parameters.filterField || [];
    var includeNotCaptured = parameters.includeNotCaptured || true;
    var orderby = parameters.orderby || 'InsertTime';
    var direction = parameters.direction || 'desc'

    return { start, end, continuationToken, pageNum, pageSize, filter, field, includeNotCaptured, orderby, direction };
}
