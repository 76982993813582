"use strict";
import angular from 'angular';
export var terminalApp = angular.module("virtualTerminalApp", [
  "angularUtils.directives.dirPagination",
  "vesparny.fancyModal",
  "sharedServices"
]);

const initializeScope = ($scope) => {
  $scope.showCredit = "No";
  $scope.showGift = "No";
  $scope.showACH = "No";
  $scope.inProgress = false;
  $scope.op = "Credit";
  $scope.tranType = "Sale"; //credit == credit sale
  $scope.mode = "Manual";
  $scope.waiting = false;

  $scope.request = $scope.request || {}; //fields will be added via angular!
  $scope.request.PartialAuth = "Allow";
  $scope.swipeEnabled = false;
  $scope.value = ["No", "No", "No"];
};

const stripUnnecessaryData = ($scope) => {
  if ($scope.mode !== "Manual") {
    delete $scope.request.Account;
    delete $scope.request.Expiration;
  }
  if ($scope.mode !== "Token") {
    delete $scope.request.Token;
  }
  if ($scope.mode !== "Swipe") {
    delete $scope.request.Track2;
    delete $scope.request.AccountKey;
    delete $scope.request.EncryptedAccount;
  }
};

export const configureGift = (
  $scope,
  $storedValue,
  $rootScope
) => {
  const msg: GiftMessage = {
    Amount: $scope.request.Amount ?? 0,
    Account: $scope.request.Account ?? "",
    CVV: $scope.request.CVV ?? 0,
  };
  $storedValue.sale(msg, function (response: any) {
    $scope.inProgress = false;
    if (response.isSuccessful) {
      successResponse(response, $scope, $rootScope);
    }
  });
};

const successResponse = function (
  response,
  $scope,
  $rootScope
) {
  $scope.request = {
    PartialAuth: "allow",
    FrontEndID: "Datacap Virtual Terminal",
  };
  $scope.mode = "Manual";
  var content = response && response.content ? response.content : {
    showReceipt: false,
    Operation: "No response",
    RefNo: "None found"
  };

  content.showReceipt = false;
  $rootScope.responses.unshift(content);
};

const configureCredit = function (
  $scope,
  $localStorage,
  $pay,
  $rootScope
) {
  stripUnnecessaryData($scope);

  $scope.request.FrontEndID = "Datacap Virtual Terminal";
  $scope.request.AuthenticationSecret = $localStorage.config().secret != undefined ? $localStorage.config().secret : "";
  $scope.request.Processor = $localStorage.config().secret != undefined ? $localStorage.config().secret.slice(-1) : "";
  $scope.tranType = "Void" ? $scope.request.tranType = $scope.tranType : "";
  $scope.refNo != null ? $scope.request.refNo = $scope.refNo : "";

  $pay["process" + $scope.tranType]($scope.request, function (response) {
    $scope.inProgress = false;

    successResponse(response, $scope, $rootScope);

  });
};

const getLast4 = function (string) {
  return string == null ? "xxxx" : string.slice(string.length - 4);
};

terminalApp.controller("virtualTerminalCtrl", [
  "$scope",
  "$pay",
  "$sv",
  "$rootScope",
  "TabService",
  "CurrencyToggleService",
  "$localStorage",
  function (
      $scope,
      $pay,
      $sv,
      $rootScope,
      TabService,
      CurrencyToggleService,
      $localStorage
  ) {
    $localStorage.save("test", "test")

    initializeScope($scope);
    $scope.configureCreditSale = configureCredit;
    $rootScope.country = "";
    $scope.$toggleVisibility = (mid) => {
      $scope.value = TabService.sortMid(mid);
      [$scope.showCredit, $scope.showGift, $scope.showACH] = $scope.value;
      $rootScope.$broadcast("toggleVisibility", $scope.value);
    };
    $scope.$watch("mode", function (newVal, oldVal) {
      $scope.swipeEnabled = newVal && newVal === "Swipe";
    });
    $scope.$watch("op", function (newVal, oldVal) {
      if (
        $scope.showEntryModeToggle &&
        newVal === "Gift" &&
        $scope.mode === "Token"
      ) {
        $scope.mode = "Manual";
      }
    });
    $scope.getLast4 = (string: string) => {
      return getLast4(string);
    };


    $scope.submitRequest = function () {

      var r = sessionStorage.getItem("terminalResponses");
      $scope.responses = [];

      if (r) {
        $scope.responses = JSON.parse(r);
      }
      $scope.inProgress = true;

      $scope.op === "Credit"
        ? $scope.configureCreditSale($scope, $localStorage, $pay, $rootScope)
        : $scope.op === "Gift"
          ? configureGift($scope, $sv, $rootScope)
          : null;
    };

    $scope.setMid = function (mid, user, businessStates) {
      if (businessStates != null) {
        $rootScope.country =
          CurrencyToggleService.checkAbbreviations(JSON.parse(businessStates));
      }

      $localStorage.config({
        secret: mid,
        user: user,
        merchantAddress1: "Test Address",
      });
      $scope.$toggleVisibility(mid);
    };

    $scope.checkAbbreviation = CurrencyToggleService.checkAbbreviations;
    var init = function () {
      $rootScope.responses = [];
      $localStorage.clearConfig();
    };
    init();
  },
]);

terminalApp.controller("TabCtrl", [
  "$scope",
  "$location",
  "$rootScope",
  "TabService",
  function ($scope, $location, $rootScope, TabService) {
    $rootScope.$on("toggleVisibility", (event, value) => {
      $rootScope.$broadcast("closeForm");

      $scope.showCredit = value[0];
      $scope.showGift = value[1];
      $scope.showACH = value[2];

      if ($scope.showCredit === "Yes") {
        $scope.openTab(null, "Credit");
      } else if ($scope.showGift === "Yes") {
        $scope.openTab(null, "Gift");
      } else if ($scope.showACH === "Yes") {
        $scope.openTab(null, "ACH");
      }
    });
    $scope.openTab = (event, tabname) => {
      $rootScope.$broadcast("openForm", tabname);
    };
  },
]);

terminalApp.controller("InputCtrl", [
  "$scope",
  "$rootScope",
  "TabService",
  function ($scope, $rootScope, TabService) {
    $rootScope.$on("openForm", (event, formname) => {
      $scope.showTab = formname;
    });
    $rootScope.$on("closeForm", (event) => {
      $scope.showTab = "Closed";
    });
  },
]);

terminalApp.controller("ConfigureCtrl", [
  "$rootScope",
  "$scope",
  "$localStorage",
  function ($rootScope, $scope, $localStorage) {
    var cfg = $localStorage.config();
    for (var key in cfg) {
      $scope[key] = cfg[key];
    }

    $scope.secretType = "password";
    var storeConfig = function () {
      var config: LocalStorageConfig = {};
      var values = Object.getOwnPropertyNames($scope);
      for (var i = 0; i < values.length; i++) {
        var key = values[i];
        if (
          key.indexOf("$") === 0 ||
          typeof $scope[key] === "function" ||
          Array.isArray($scope[key])
        ) {
          continue;
        }
        config[key] = $scope[key];
      }
      config = $localStorage.config(config);
      $rootScope.config = config;
      for (var k in config) {
        $scope[k] = config[k];
      }
    };

    $scope.saveConfig = function () {
      if ($scope.secret && $scope.secret !== "") {
        if ($scope.secret.charAt($scope.secret.length - 1) == "A") {
          $scope.allowPartialAuth = true;
        }
        storeConfig();
      } else {
      }
    };
  },
]);
terminalApp.controller("StoredValueCtrl", [
  "$scope",
  "$sv",
  function ($scope, $sv) {
    $scope.op = "Sale";
    $scope.responses = [];
    $scope.swiperMode = "Gift";
    $scope.resetAdvanced = function () {
      $scope.promo = false;
      $scope.lock = false;
      $scope.overrideCVV = false;
      $scope.invoiceNo = "";
      $scope.identifier = "";
      $scope.creditLimit = "";
      if ($scope.op === "Create") {
        $scope.account = "";
      }
    };
    $scope.resetAdvanced();
    var r = sessionStorage.getItem("svResponses");
    if (r) {
      $scope.responses = JSON.parse(r);
    }
    $scope.$watch("op", function (newVal, oldVal) {
      $scope.resetAdvanced();
    });
    $scope.submitTransaction = function () {
      var op = $scope.op;
      var payload = $scope["build" + op + "Request"]();
      $sv[op.toLowerCase()](payload, function (response) {
        if (response.isSuccessful) {
          var result = response.content;
          result.Operation = op;
          result.Voided = false;
          if (op === "Balance") {
            result.Amount = result.Balance;
          } else {
            $scope.account = "";
            $scope.resetAdvanced();
            $scope.cvv = "";
            $scope.swipeEnabled = true;
            $scope.responses.unshift(result);
          }
          $scope.amount = "";
        }
      });
    };
    $scope.buildBalanceRequest = function () {
      var result: DatacapTransactionResult = {
        Account: $scope.account,
        CVV: $scope.cvv,
      };
      if ($scope.showAdvanced) {
        result.Identifier = $scope.identifier;
        result.OverrideCVV = $scope.overrideCVV;
      }
      return result;
    };
    $scope.buildCreateRequest = function () {
      return {
        Amount: $scope.amount,
        NewIdentifier: $scope.identifier,
        InvoiceNo: $scope.invoiceNo,
        Promo: $scope.promo,
        Lock: $scope.lock,
      };
    };
    $scope.buildSetRequest = function () {
      var req = $scope.buildBalanceRequest();
      delete req.Identifier;
      req.NewIdentifier = $scope.identifier;
      req.Lock = $scope.lock;
      req.CreditLimit = $scope.creditLimit;
      return req;
    };
    $scope.buildSaleRequest = function () {
      var req = $scope.buildBalanceRequest();
      req.Amount = $scope.amount;
      if ($scope.showAdvanced) {
        req.Identifier = $scope.identifier;
        req.OverrideCVV = $scope.overrideCVV;
        req.InvoiceNo = $scope.invoiceNo;
      }
      return req;
    };
    $scope.buildLoadRequest = function () {
      var req = $scope.buildSaleRequest();
      if ($scope.showAdvanced) {
        req.Promo = $scope.promo;
      }
      return req;
    };
    $scope.showVoidButton = function (r) {
      return (
        r.Voided === false && (r.Operation === "Sale" || r.Operation === "Load")
      );
    };
    $scope.voidStoredValue = function (originalResponse) {
      $sv.void(originalResponse.RefNo, function (response) {
        if (!response.isSuccessful) {
          return;
        }
        response.content.Operation = "Void";
        $scope.responses.unshift(response.content);

        originalResponse.Voided = true;
      });
    };
  },
]);
terminalApp.controller("NotificationCtrl", [
  "$rootScope",
  "$timeout",
  function ($rootScope, $timeout) {
    $rootScope.notifications = [];
    $rootScope.count = 0;
    $rootScope.showSuccess = function (message) {
      var note = {
        class: "alert-success",
        message: message,
      };
      $rootScope.notifications = [note];
      $timeout(function () {
        $rootScope.closeNotification(note);
      }, 10000);
    };
    $rootScope.showError = function (message) {
      var note = {
        class: "alert-danger",
        message: message,
      };
      $rootScope.notifications.unshift(note);
      $timeout(function () {
        $rootScope.closeNotification(note);
      }, 10000);
    };
    $rootScope.closeAll = function () {
      $rootScope.notifications = [];
    };
    $rootScope.closeNotification = function (item) {
      var index = $rootScope.notifications.indexOf(item);
      $rootScope.notifications.splice(index, 1);
    };

    $rootScope.$on("$locationChangeSuccess", function () {
      $rootScope.closeAll();
    });
  },
]);

angular.module('virtualTerminalApp').controller("AchCtrl", [
  "$scope",
  "$ach",
  "$rootScope",
  function ($scope, $ach, $rootScope) {
    $scope.op = "Authorize";
    $scope.responses = [];
    var r = sessionStorage.getItem("achResponses");
    if (r) {
      $scope.responses = JSON.parse(r);
    }
    $scope.submitTransaction = function () {
      var payload = $scope["build" + "Authorize" + "Request"]();
      $ach["authorize"](payload, function (response, $rootScope) {
        if (response.isSuccessful) {
          var result = response.content;
          result.Voided = false;
          $scope.AcctNo = "";
          $scope.RoutingNo = "";
          $scope.AcctType = "";
          $scope.Amount = "";
          $scope.SequenceNo = "";
          $scope.CustFirstName = "";
          $scope.CustLastName = "";
          $scope.InvoiceNo = "";
          $scope.responses.unshift(result);
          $scope.Amount = "";
          $scope.acceptCheck = false;
        }
      });
    };
    $scope.buildAuthorizeRequest = function () {
      var req = {
        RoutingNo: $scope.RoutingNo,
        AcctNo: $scope.AcctNo,
        AcctType: $scope.AcctType,
        Amount: $scope.Amount,
        SequenceNo: $scope.SequenceNo,
        CustFirstName: $scope.CustFirstName,
        CustLastName: $scope.CustLastName,
        EntryClass: $scope.EntryClass,
        InvoiceNo: $scope.InvoiceNo,
      };
      return req;
    };
    $scope.showVoidButton = function (r) {
      return r.Voided === false && r.Operation === "Authorize";
    };
    $scope.voidAuthorizeValue = function (originalResponse) {
      $ach.void(
        originalResponse.RefNo,
        originalResponse.Token,
        function (response) {
          if (!response.isSuccessful) {
            return;
          }
          response.content.Operation = "Void";
          $scope.responses.unshift(response.content);

          originalResponse.Voided = true;
        }
      );
    };
  },
]);

angular
  .module("myApp.terminal", ["ngRoute"])

  .config([
    "$routeProvider",
    function ($routeProvider) {
      $routeProvider.when("/terminal", {
        templateUrl: "terminal/terminal.html",
        controller: "TerminalCtrl",
      });
    },
  ])

  .controller("TerminalCtrl", [
    "$scope",
    "$pay",
    "$sv",
    "$localStorage",
    "$rootScope",
    function ($scope, $pay, $sv, $localStorage, $rootScope) {
      $scope.op = "Credit";
      $scope.tranType = "Sale"; //credit == credit sale
      $scope.showEntryModeToggle =
        $scope.config.showSwiper && $scope.config.showManual;
      if ($scope.config.showSwiper) {
        $scope.mode = $scope.config.showManual ? "Manual" : "Swipe";
      } else {
        $scope.mode = "Manual";
      }
      var r = sessionStorage.getItem("terminalResponses");
      $scope.responses = [];

      if (r) {
        $scope.responses = JSON.parse(r);
      }
      $scope.request = $scope.request || {}; //fields will be added via angular!
      $scope.swipeEnabled = false;
      $scope.$watch("mode", function (newVal, oldVal) {
        $scope.swipeEnabled = newVal && newVal === "Swipe";
      });
      $scope.$watch("op", function (newVal, oldVal) {
        if (
          $scope.showEntryModeToggle &&
          newVal === "Gift" &&
          $scope.mode === "Token"
        ) {
          $scope.mode = "Manual";
        }
      });
      $scope.getLast4 = (string: string) => {
        return getLast4(string);
      };
      $scope.submitRequest = function () {
        if ($scope.op === "Credit") {
          configureCredit($scope, $localStorage, $pay, $rootScope);
        } else if ($scope.op === "Gift") {
          configureGift($scope, $sv, $rootScope);
        }
      };
    },
  ]);
