import * as angular from 'angular';
import * as momenttimezone from 'moment-timezone';
class TimezoneController {
  options: { id: string, name: string }[];
  guess: string;

  // US and Canada timezones use one of two prefixes in moment.js - America or Pacific.
  desiredTimezones : string[] = ['Canada', 'US']

  constructor() {
    this.options = [];

    // Get a list of all available time zones
    const zoneNames = momenttimezone.tz.names();
    this.guess = momenttimezone.tz.guess();
    try {
      // Create an array of timezone objects with id and name properties
      for (const zoneName of zoneNames) {
        if(!zoneName){
          continue;
        }
        //console.log(`zoneName: ${zoneName}`)
        if (!this.desiredTimezones.some(tz => zoneName.startsWith(tz))) {
          continue;
        }
        this.options.push({id: zoneName, name: zoneName});
      }

      // Sort the array by the name after the / in the middle, ignoring the prefix. eg. America/Chicago -> Chicago
      this.options.sort((a, b) => {
        const aName = a.name.split('/')[1];
        const bName = b.name.split('/')[1];
        return aName.localeCompare(bName);
      });

      // Add the guess as the first element in the array if it is defined
      if (this.guess) {
        this.options.unshift({ id: this.guess, name: this.guess });
      }
    } catch (error) {


    }
    
  }
}

angular.module('transactionsApp').component('timezoneDropdown', {
  bindings: {
    selected: '<',
    onChange: '&'
  },
  template: `
            <div>
              <label for="timezone">Timezone</label>
            </div>
            <div>
              <standard-dropdown options="$ctrl.options" type-element="a timezone" selected="$ctrl.selected ? $ctrl.selected : $ctrl.guess" on-change="$ctrl.onChange()"></standard-dropdown>
              <div>{{ $ctrl.zoneNames }}</div>
            </div<
  `,
  controller: TimezoneController
});