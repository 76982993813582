angular.module('transactionsApp').service('$loadDataService', ['$creditTransactions',
  function ($creditTransactions) {
    var service = {
      fetchData: fetchData,
      loadInitialData: loadInitialData,
      loadDataOnChange: loadDataOnChange
    };
    function loadInitialData(parameters) {


        return Promise.all([
            fetchData('getFilteredTransactionsLookup', parameters, response => response.content).then(result => {

              return result;
            }).catch(error => {

              throw error;
            }),

            fetchData('getSum', parameters, response => response.content[0]).then(result => {

              return result;
            }).catch(error => {

              throw error;
            }),
            fetchData('getCount', parameters, response => response.content[0]).then(result => {

              return result;
            }).catch(error => {

              throw error;
            })

        ])
        .then(results => {


          const [rawResults, summaryTotal, totalItems, tranCodeList, batches] = results;

          console.log("totalItems")
          return { rawResults: rawResults, summaryTotal: summaryTotal, summaryCount: totalItems, totalItems: totalItems, tranCodeList: tranCodeList, batches: batches };
        })
        .catch(error => {

          throw error;
        });

    }
    function fetchData(action, parameters, processResponse) {

      return new Promise((resolve, reject) => {
        if (typeof $creditTransactions[action] !== "function") {

          reject(new Error(`Action '${action}' is not a function.`));
          return;
        }

        $creditTransactions[action](parameters, (response) => {

          if (response == null) {

            reject(new Error('Failed to load data. '));
          }
          if (!response.wasSuccessful) {

            reject(new Error('Failed to load data. ' + response.message));
          } else {

            var result = processResponse(response);

            resolve(result);


          }
        }).catch((error) => {

          reject(error);
        });
      });
    }


    function loadDataOnChange(parameters) {
        console.log("load data on change", parameters)
        return Promise.all([
            fetchData('getFilteredTransactionsLookup', parameters, response => response.content).then(result => {

              return result;
            }).catch(error => {

              throw error;
            }),

            fetchData('getSum', parameters, response => response.content[0]).then(result => {

              return result;
            }).catch(error => {

              throw error;
            }),
            fetchData('getCount', parameters, response => response.content[0]).then(result => {

              return result;
            }).catch(error => {

              throw error;
            })
        ])
        .then(results => {


          const [rawResults, summaryTotal, totalItems] = results;
          return { rawResults: rawResults, summaryTotal: summaryTotal, totalItems: totalItems, summaryCount: totalItems };
        })
        .catch(error => {

          throw error;
        });
    }


    return service;
}]);


