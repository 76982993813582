import * as angular from 'angular';

export class BatchSyncController {

  constructor() {
  }
}

angular.module('transactionsApp').component('batchSync', {
  template: `<div >

              <label>Batch Sync Using Time</label>

              <toggle-checkbox-slider></toggle-checkbox-slider>

              <batch-sync-modal ng-show="$root.showSyncBatch"></batch-sync-modal>
              
              <current-time></current-time>

            </div>`,
  controller: ['$rootScope', BatchSyncController]
});
