class DropdownController {
  options: { id: number, name: string }[];
  defaultOption: { id: number, name: string } | undefined;

  constructor() {
    this.options = [];
  }

  $onInit() {
    // Add the default option to the beginning of the array if it is defined
    if (this.defaultOption) {
      this.options.unshift(this.defaultOption);
    }
  }
}

angular.module('transactionsApp').component('standardDropdown', {
  bindings: {
    options: '<',
    typeElement: '@',
    defaultOption: '<?'
  },
  template: `
    <select class="form-control" ng-model="$ctrl.selectedOption" ng-options="option.id as option.name for option in $ctrl.options" ng-dropdown required on-change="onChange()">
      <option value="" disabled selected hidden>Select {{$ctrl.selected ? $ctrl.selected : $ctrl.typeElement}}</option>
      <option ng-repeat="option in $ctrl.options" value="{{option.id}}">{{option.name}}</option>
    </select>
  `,
  controller: DropdownController,
  transclude: true
});