
class VoidReturnModalAllController {


  constructor($scope, $rootScope) {
    $scope.isModalOpen = true;
    $scope.root = $rootScope;
    $scope.showAlert = false;
    $scope.toggleModal = () => {
      $scope.isModalOpen = !$scope.isModalOpen;
      $rootScope.isModalOpen = false;
    }
    $scope.toggleModalWarn = () => {
      $scope.showAlert = true;
    }

    $scope.acceptAlert = () => {
      $scope.showAlert = false;
      $scope.isModalOpen = false;
      $rootScope.isModalOpen = false;
    }

    $scope.rejectAlert = () => {
      $scope.showAlert = true;
      $scope.isModalOpen = true;
      $rootScope.isModalOpen = false;
    }

  }

}
angular.module('transactionsApp').component('voidReturnAllModal', {
  bindings: {
    buttonText: '@',
    modalId: '@'
  },
  template: `
                <!-- Button trigger modal -->

                <button class="btn btn-danger fixed-width" data-toggle="modal" data-target="#{{ $ctrl.modalId }}" tooltip-placement="right" style="padding: 0.1em 0.5em;" ng-click="root.toggleModalMain()">
                  {{ $ctrl.buttonText }}
                </button>

                <!-- Modal -->
                <div class="modal fade" id="{{ $ctrl.modalId }}" tabindex="-1" role="dialog" aria-labelledby="{{ $ctrl.modalId }}" aria-hidden="true" ng-style="{'backdrop-filter':'blur(5px)','top':'25%'}">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="{{ $ctrl.ModalId }}">Would you like to <strong>{{$ctrl.buttonText}}</strong> these transactions?</h5>
                            <div class="alert alert-danger" ng-show="showAlert">
                              <strong>Warning!</strong> Are you sure you want to {{ $ctrl.buttonText }} these transactions? This is not a reversible transaction.
                              <button type="button"  data-dismiss="modal" ng-click="acceptAlert()">Yes, {{ $ctrl.buttonText }} these transactions</button>
                              <button <button type="button"  data-dismiss="modal" ng-click="rejectAlert()">No, do not {{ $ctrl.buttonText }} these transactions</button>
                            </div>
                          </div>
                          <hr/>

                          <div class="modal-body">
                            You have selected to {{ $ctrl.buttonText }} all transactions on the page that are eligible to be {{ $ctrl.buttonText }}ed. This action cannot be reversed.
                            By clicking '{{ $ctrl.buttonText }} These Transactions', you are performing an irreversible action.
                          </div>

                          <div class="modal-footer">
                              <button type="button" class="btn btn-default" data-dismiss="modal" ng-click="toggleModal()">Cancel</button>
                              <button type="button"  class="btn btn-danger" ng-click="toggleModalWarn()">{{ $ctrl.buttonText }} These Transactions</button>
                          </div>
                      </div>
                    </div>
                </div>
            `,
  controller: ['$scope', '$rootScope', VoidReturnModalAllController]
});
