/**
 * Mess with the order of these scripts at your own risk. The CSS files load in the order they are listed and overwrite each other.
 * The JS files will have undefined behavior if they are not loaded in the correct order.
 * If something really random goes wrong, such as Angular can't instantiate for a test, or all angular data bindings show as {{}} or the background color
 * randomly turns white, something in this loading order has changed. Dont' try to reason your way through it, find the last working index.js, put it here, and
 * then slowly add your changes.
 */// Icon files
import '../wwwroot/pages/ico/60.png';
import '../wwwroot/pages/ico/76.png';
import '../wwwroot/pages/ico/120.png';
import '../wwwroot/pages/ico/152.png';
import '../wwwroot/assets/plugins/rickshaw/rickshaw.min.css';
import '../wwwroot/pages/ico/152.png';
import '../wwwroot/pages/ico/152.png';
import '../wwwroot/assets/plugins/rickshaw/rickshaw.min.css';
import '../wwwroot/assets/plugins/rickshaw/rickshaw.min.css';
import 'angular';
import 'jquery';

import '../wwwroot/assets/plugins/pace/pace.min.js';
import '../wwwroot/assets/plugins/pace/pace.min.js';
import 'angular-cookies';
import 'angular-sanitize';
import 'angular-route';
import 'daterangepicker';
import 'angular-ui-bootstrap';
import "../wwwroot/assets/plugins/font-awesome/css/font-awesome.css";
import '../wwwroot/lib/angularjs-daterangepicker/src/angular-daterangepicker'
import '../wwwroot/assets/plugins/bootstrap3-wysihtml5/bootstrap3-wysihtml5.min.css';
import '../wwwroot/assets/plugins/bootstrap-tag/bootstrap-tagsinput.css';
import '../wwwroot/assets/plugins/jquery-scrollbar/jquery.scrollbar.css';
import '../wwwroot/assets/plugins/bootstrap-select2/select2.css';
import '../wwwroot/assets/plugins/switchery/css/switchery.min.css';
import '../wwwroot/assets/plugins/nvd3/nv.d3.min.css';
import '../wwwroot/assets/plugins/jquery-ui/jquery-ui.min';
import '../wwwroot/assets/plugins/bootstrapv3/js/bootstrap.min.js';
import '../wwwroot/assets/plugins/jquery/jquery-easy.js';
import '../wwwroot/assets/plugins/jquery-unveil/jquery.unveil.min.js';
import '../wwwroot/assets/plugins/jquery-bez/jquery.bez.min.js';
import '../wwwroot/assets/plugins/jquery-ios-list/jquery.ioslist.min.js';
import '../wwwroot/assets/plugins/jquery-actual/jquery.actual.min.js';
import '../wwwroot/assets/plugins/bootstrap-select2/select2.min.js';
import '../wwwroot/assets/plugins/classie/classie.js';
import '../wwwroot/assets/plugins/switchery/js/switchery.min.js';
import '../wwwroot/assets/plugins/bootstrap-timepicker/bootstrap-timepicker.min.css';
import '../wwwroot/lib/angular-fancy-modal/dist/angular-fancy-modal.min.css';

import '../wwwroot/assets/plugins/pace/pace-theme-flash.css';
import '../wwwroot/lib/angular-fancy-modal/dist/angular-fancy-modal.min.css';
import '../wwwroot/lib/angular-fancy-modal/dist/angular-fancy-modal-theme-classic.css';
import '../wwwroot/pages/css/pages-icons.css';
import '../wwwroot/pages/css/default.css';
import '../wwwroot/assets/plugins/bootstrapv3/css/bootstrap.css';

import '../wwwroot/lib/moment/moment.js';

import '../wwwroot/lib/angularUtils-pagination/dirPagination';
import '../wwwroot/lib/angular-fancy-modal/dist/angular-fancy-modal.min';

import "../wwwroot/assets/plugins/bootstrap3-wysihtml5/bootstrap3-wysihtml5.min.css";
import "../wwwroot/assets/plugins/bootstrap-tag/bootstrap-tagsinput.css";
import "moment-timezone";
import "../wwwroot/assets/plugins/bootstrap-timepicker/bootstrap-timepicker.min.css";
import "../wwwroot/lib/angular-fancy-modal/dist/angular-fancy-modal.min.css";
import "../wwwroot/lib/angular-fancy-modal/dist/angular-fancy-modal-theme-classic.css";

import '../wwwroot/angular/Services/Shared/localStorage';
import '../wwwroot/angular/Services/Shared/pay';

import '../wwwroot/angular/Report/reportApp.js';
import '../wwwroot/angular/terminal/VirtualTerminalApp.ts';

import '../wwwroot/angular/Transactions/TransactionsApp';
import '../wwwroot/angular/Services/creditTransactions';
import '../wwwroot/angular/Services/FilterService';
import '../wwwroot/angular/Services/loadDataService';
import '../wwwroot/angular/Services/pageSetupService';
import '../wwwroot/angular/Services/TabService';

import '../wwwroot/angular/Services/currencyToggle.ts';
import '../wwwroot/angular/Services/sv';
import '../wwwroot/angular/Services/ach';
import '../wwwroot/angular/Services/TransactionDetails/ChangeOrganization'
import '../wwwroot/angular/Services/TransactionDetails/AggregateLists'
import '../wwwroot/angular/Services/TransactionDetails/TableNameService'
import '../wwwroot/angular/Services/TransactionDetails/SelectionToRunTransactionsService'
import '../wwwroot/angular/Services/TransactionDetails/ReferenceDetails'

import '../wwwroot/angular/Services/selectByMid.ts';
import '../wwwroot/angular/Components/batch-sync/toggle-checkbox-slider.ts';
import '../wwwroot/angular/Components/batch-sync/current-time.ts'
import '../wwwroot/angular/Components/alerts/patch-warning.js';
import '../wwwroot/angular/Components/tooltips/sync-tooltip.ts';
import '../wwwroot/angular/directives/modal-dialog.ts';
import '../wwwroot/angular/Services/reportService.js';
import '../wwwroot/angular/Components/batch-sync/modal-alert.ts';
import '../wwwroot/angular/Components/batch-sync/time-input.ts'
import '../wwwroot/angular/Components/batch-sync/batch-sync-modal.ts';
import '../wwwroot/angular/Components/batch-sync/timezones.ts';
import '../wwwroot/angular/Components/batch-sync/standard-dropdown.ts';
import '../wwwroot/angular/Components/batch-sync/batch-sync.ts';

import '../wwwroot/angular/Components/void-return/void-return-modal.ts';
import '../wwwroot/angular/Components/void-return/void-return.ts';
import '../wwwroot/angular/Components/void-return/void-return-all-modal.ts';
import '../wwwroot/angular/Components/void-return/void-return-all.ts';
import '../wwwroot/assets/plugins/nvd3/lib/d3.v3.js';
import '../wwwroot/assets/plugins/nvd3/nv.d3.min.js';
import '../wwwroot/assets/plugins/nvd3/src/utils.js';
import '../wwwroot/assets/plugins/nvd3/src/tooltip.js';
import '../wwwroot/assets/plugins/nvd3/src/interactiveLayer.js';
import '../wwwroot/assets/plugins/nvd3/src/models/axis.js';
import '../wwwroot/assets/plugins/nvd3/src/models/line.js';
import '../wwwroot/assets/plugins/nvd3/src/models/lineWithFocusChart.js';
import '../wwwroot/assets/plugins/d3/d3.min.js';

import '../wwwroot/assets/js/scripts.js';

