angular.module('sharedServices', [])
  .service('$localStorage', [function () {
    var self = this;
    const unallowedKeys = ['object', 'boolean', 'function'];
    const MAX_KEY_LENGTH = 128;
    const MAX_VALUE_LENGTH = 1024;
    this.save = function (key, value) {
      if (!key || !value) {
        throw new Error('Key and value are required');
      }
      if (unallowedKeys.includes(typeof key)) {
        throw new Error('Invalid key type')
      }
      if (key.length >= MAX_KEY_LENGTH || value.length >= MAX_VALUE_LENGTH) {
        throw new Error('Key or value are not valid');
      }
      localStorage.setItem(key, JSON.stringify(value));
    };

    this.get = function (key) {
      var value = localStorage.getItem(key);
      return value ? JSON.parse(value) : '';
    };

    this.config = function (newConfig) {

      var result = self.get('config') || {};

      // if we're adding a config, assign the values into the result temp object, otherwise, make sure newconfig is defined
      newConfig ? Object.assign(result, newConfig) : newConfig = {};

      var secret = newConfig.secret || result.secret || '';

      var url = window.location.href;
      newConfig.merchantAddress1 ? result.merchantAddress1 = newConfig.merchantAddress1 : result.merchantAddress1 = '';
      this.assignUrlsBasedOnNewConfig(url, result);
      result.secret = secret.toUpperCase();
      newConfig.secret = secret.toUpperCase();
      this.configurePartialAuthBasedOnSecret(secret, result, newConfig);
      self.save('config', result);
      return result;
    };

    this.clearConfig = function () {
      var result = self.get('config') || {};
      var url = "-cert";
      result.secret = "";
      this.assignUrlsBasedOnNewConfig(url, result);

      self.save('config', result);


    };

    this.createGuid = function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    };

    this.assignUrlsBasedOnNewConfig = (url, result) => {
      if (url.includes("-cert") || url.includes("localhost") || url.includes("-test")) {
        result.url = 'https://pay-cert.dcap.com/v1/';
        result.reportingUrl = "https://reporting-cert.dcap.com";
        result.tokenUrl = "https://token-cert.dcap.com/v1/";
      } else {
        result.url = 'https://pay.dcap.com/v1/';
        result.reportingUrl = "https://reporting.dcap.com";
        result.tokenUrl = "https://token.dcap.com/v1/";
      }
    }

    this.configurePartialAuthBasedOnSecret = (secret, result, newConfig) => {
      if (secret.charAt(secret.length - 1) == 'A') {
        result.allowPartialAuth = true;
      } else {
        result.allowPartialAuth = newConfig.allowPartialAuth ? newConfig.allowPartialAuth : false;
      }
    }
  }]);
