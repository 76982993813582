'use strict';
import { transactionApp } from '../Transactions/TransactionsApp';
import * as angular from 'angular';
const stateNames: string[] = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
  'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
  'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
  'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah',
  'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const stateAbbreviations = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID',
  'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT',
  'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
];

const caProvincesAbbr = [
  'ON', 'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'PE', 'QC', 'SK', 'YK'
];

const caProvincesNames = [
  'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador',
  'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'
];

class CurrencyToggleService {
  private caArrayProvincesAbbr: any;
  private usStatesAbbr: any;
  private caProvincesNames: any;
  private caProvincesAbbr: any;
  private currentCountry: string;
  constructor() {
    let self = this;
    this.usStatesAbbr = stateAbbreviations;
    this.caProvincesNames = caProvincesNames;
    this.caProvincesAbbr = caProvincesAbbr;
    this.validateInput = this.validateInput.bind(this);
    this.checkAbbreviations = this.checkAbbreviations.bind(this);
    this.currentCountry = '';


  }

  private validateInput(input: string): boolean {
    if (input == null || typeof input !== 'string' || input.length > 3 || input === '' || input == undefined) {
      return false;
    }
    return true;
  }

  public checkAbbreviations(abbreviationsToCheck: string[]): string {
    // This should assign abbreviations to two currencies: US dollars or Canadian dollars. However, you need to pass a string[].
    // The problem with that is that it plays strangely with JSON objects being returned from network calls or forms. Sometimes you have to parse in the parameters
    // Sometimes you have to pass with a []
    // If this currency toggle is struggling with almost anything, examine the shape of the data being passed in.

    let country: string = this.currentCountry;

    if (!abbreviationsToCheck || !Array.isArray(abbreviationsToCheck)) {
      return country;
    }
    for (let abbreviation of abbreviationsToCheck) {

      if (!this.validateInput(abbreviation)){return country};

      if (this.usStatesAbbr.includes(abbreviation)) {
        country = 'USD';
        this.currentCountry = 'USD';

      } else if (this.caProvincesAbbr.includes(abbreviation)) {
        country = 'CAD';
        this.currentCountry = 'CAD';

      } else {
        country = 'USD';
        this.currentCountry = 'USD';
      }

    }

    return country;
  }

}

angular.module('transactionsApp').service('CurrencyToggleService', [CurrencyToggleService]);

