
'use strict';
angular.module('virtualTerminalApp').service('$ach', ['$http', '$rootScope', '$localStorage', function ($http, $rootScope, $localStorage) {
  this.headers = {
    'Authorization': $localStorage.config().secret,
    'Content-Type': 'application/json'

  };
  this.isValid = false;
  this.payload = {};
  this.buildSuccessHandler = function (callback) {
    return function (response) {
      response.data.guid = $localStorage.createGuid();
      var msg = (response.data.Status || "Success")
        + " (" + (response.data.Account || "Authorization") + ")"
        + ": " + response.data.Amount;
      if (response.config.url.includes("/void")) {
        response.data.Operation = "Void";
      } else {
        response.data.Operation = "Authorize";
      }
      $rootScope.showSuccess(msg);
      $rootScope.showProgress = false;
      if (callback) {
        callback({ content: response.data, isSuccessful: true });
      }
    };
  };
  var buildFailureHandler = function (callback) {
    return function (response) {
      var data = response.data || {};
      var formattedMsg = (data.Status || "ERROR")
        + " (" + (data.Account || data.Identifier || "Authorization") + ")"
        + ":" + data.Message;
      $rootScope.showError(formattedMsg);
      $rootScope.showProgress = false;
      if (callback) {
        callback({ content: data, isSuccessful: false, formattedMsg: formattedMsg });
      }
    };
  };


  /**
   * 
   * @param {*} whitelist - an array of fields to whitelist
   * @returns - an array of fields to whitelist, with any fields that contain '||' expanded into multiple fields
   *           e.g. ['Amount', 'AcctNo||RoutingNo'] => ['Amount', 'AcctNo', 'RoutingNo']
   *          e.g. ['Amount', 'AcctNo||RoutingNo', 'CustFirstName||CustLastName'] => ['Amount', 'AcctNo', 'RoutingNo', 'CustFirstName', 'CustLastName']
   * 
   */
  this.expandOrClausesInWhitelist = (whitelist) => {
    return whitelist.flatMap(function(field) { return field.includes('||') ? field.split('||') : field; });
  };

  /**
   * 
   * @param {*} payload - the payload to filter
   * @param {*} whitelist - an array of fields to whitelist
   * @returns - a new object containing only the fields in the whitelist
   * 
   */
  this.parsePayloadWithWhiteList = (payload, whitelist) => {
    const expandedWhiteList = this.expandOrClausesInWhitelist(whitelist);
    return Object.keys(payload).reduce(function(filteredPayload, currentKey) {
      if (expandedWhiteList.includes(currentKey)) {
        return { ...filteredPayload, [currentKey]: payload[currentKey] };
      } else {
        return filteredPayload;
      }
    }, {});
  };

  this.shouldValidate =  function(field, fieldsToValidate){ return fieldsToValidate.indexOf(field) !== -1; };

  this.validateField = function(field, fieldsToValidate) {

    if (this.shouldValidate(field, fieldsToValidate) && this.isNullOrEmpty(field)) {
      $rootScope.showError('invalid ' + field.toLowerCase());
      buildFailureHandler()({
        data: {
          Message: 'invalid ' + field.toLowerCase()
        }
      });
      
      return false;
    }
    return true;
  };

  this.isNullOrEmpty = function (val) {

    // Non-strings - whether objects or custom types or numbers - should return true because true is a failing event for "is null or empty"
    if(typeof val !== 'string') return true;
    return (!val || val.trim() === ''); 
  };

  this.isValidPayload = (payload, fieldsToValidate) => {
    for (var prop in payload) {
      if (payload.hasOwnProperty(prop)) {
        if (!this.validateField(prop, fieldsToValidate)) {

          return false;
        }
      }
    }
    return true;
  };

  this.allConditionalFieldsAreEmpty = (conditionalFields, payload) => {
    let allAreEmpty = true;
    for (var i = 0; i < conditionalFields.length; i++) {
      var field = conditionalFields[i];
      if (!this.isNullOrEmpty(payload[field])) {
        allAreEmpty = false;
      }
    }
    return allAreEmpty;
  };

  this.parseConditionalRequirementsAndValidate = (payload, conditionalRequirements) => {
    if(conditionalRequirements.length === 0) {

      // Empty conditional requirements are valid but the payload is not validated
      this.isValid = true;
      return true;
    }
    conditionalRequirements = this.expandOrClausesInWhitelist(conditionalRequirements);
    const allConditionalFieldsAreEmpty = this.allConditionalFieldsAreEmpty(conditionalRequirements, payload);
    if (allConditionalFieldsAreEmpty) {
      buildFailureHandler()({ data: { Message: 'At least one of the following is required: ' + conditionalRequirements.join() + '.'} });
    }
    this.isValid = !allConditionalFieldsAreEmpty;
    return this.isValid;
  };

  this.conditionalRequirements = (fieldsToValidate) => {return fieldsToValidate.filter(function (field) { return (field.indexOf('||') > -1); });}
  
  function hasAllFields(payload, fieldsToValidate, conditionalRequirements = []) {
    var missingFields = [];
    fieldsToValidate.forEach(function(field) {
      if (!payload.hasOwnProperty(field) && !conditionalRequirements.includes(field)) {
        buildFailureHandler()({ data: { Message: 'invalid ' + field.toLowerCase() } });
        missingFields.push(field);
      }
    });
    return missingFields;
  }

  this.validatePayload = function (payload, fieldsToValidate = ['Amount', 'AcctNo', 'RoutingNo', 'CustFirstName', 'CustLastName', 'AcctType']) {
    // Remove non-strings from fields to validate
    fieldsToValidate = fieldsToValidate.filter(function(element) {
      return typeof element === 'string';
    });


    //only fields that contain '||'
    var conditionalRequirements = this.conditionalRequirements(fieldsToValidate);

    if(hasAllFields(payload, fieldsToValidate, conditionalRequirements).length > 0) {
      return false;
    };

    this.payload = this.parsePayloadWithWhiteList(payload, fieldsToValidate);

    this.isValid = this.isValidPayload(this.payload, fieldsToValidate) && this.parseConditionalRequirementsAndValidate(this.payload, conditionalRequirements);

    return this.isValid;
  };

  this.authorize = function (payload, callback) {
    this.payload = payload;
    if (!this.validatePayload(payload)) {

      return;
    }

    $rootScope.showProgress = true;
    this.headers.Authorization = $localStorage.config().secret;
    $http({
      method: 'POST',
      url: $localStorage.config().url + 'Ach/Authorize',
      data: JSON.stringify(this.payload),
      headers: this.headers
    }).then(this.buildSuccessHandler(callback), buildFailureHandler(callback));
  };

  this.void = function (refNo, token, callback) {

    $rootScope.showProgress = true;

    this.headers.Authorization = $localStorage.config().secret;
    var tempPayload = {};
    tempPayload.Token = token;
    $http({
      method: 'POST',
      url: $localStorage.config().url + 'Ach/Authorize/' + refNo + '/void',
      data: JSON.stringify(tempPayload),
      headers: this.headers
    }).then(this.buildSuccessHandler(callback), buildFailureHandler(callback));
  };
}]);
