import * as angular from 'angular';

export class VoidReturnAllController {

  constructor() {
  }
}

angular.module('transactionsApp').component('voidReturnAll', {
  bindings: {
    buttonText: '@',
    modalId: '@'
  },
  template: `
              <void-return-all-modal button-text="{{$ctrl.buttonText}}" modal-id="{{$ctrl.modalId}}"></void-return-all-modal>
            `,
  controller: ['$rootScope', VoidReturnAllController]
});

