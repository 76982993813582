angular.module('transactionsApp').service('$filterService', [function () {
  var service = {
    init: init,
    clearAll: clearAll,
    processFilters: processFilters,
    setupParametersOnFilterChange: setupParametersOnFilterChange,
    createParametersForAllTransactionsNotPaginated: createParametersForAllTransactionsNotPaginated,
    changeParameters: changeParameters
  };

  function init() {
    // Initialize parameters and return them
    var parameters = {
      pageNum: 1,
      pageSize: 25,
      totalItems: 0,
      summaryTotal: 0,
      summaryCount: 0,
      successMsg: null,
      errorMsg: null,
      firstLoad: true,
      showNonCaptured: true,
      rawResults: {},
      transactions: [],
      inProgress: false,
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      filters: {},

      details: null,
      parameters: {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day')
      }
    };
    return parameters;
  };
  function changeParameters(startDate, endDate, continuationToken, pageNum, pageSize, filterList, filterField, orderBy, direction) {
    return {
      startDate: startDate || new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      endDate: endDate || new Date(),
      continuationToken: continuationToken || '',
      pageNum: pageNum || 1,
      pageSize: pageSize || 25, // Default page size
      filters: filterList || [],
      filterField: filterField || [],
      includeNotCaptured: true,
      orderby: orderBy,
      direction: direction
    };
  };
  function createParametersForAllTransactionsNotPaginated(startDate, endDate, totalItems) {

    return {
      startDate: startDate,
      endDate: endDate,
      continuationToken: '',
      pageNum: 1,
      pageSize: totalItems,
      filters: [],
      filterField: [],
      includeNotCaptured: true
    };
  };
  function processFilters(filters) {
    var filterList = [];
    var filterField = [];
    for (var key in filters) {
      if (filters.hasOwnProperty(key) && typeof filters[key] === 'string' && filters[key] !== '') {
        var field = filters[key];
        filterList.push(field);

        filterField.push(key);
      }
    }
    return { filterList, filterField };
  }

  function clearAll() {
    // Return a new filters object and any other state resets
    return {
      filters: {
        mid: '',
        last4: '',
        invoice: '',
        reqResp: '',
        amount: '',
        trancode: '',
        batch: '',
        cardBrand: '',
        refNo: '',
        acctNo: '',
        payApiId: '',
        status: ''
      },

      filterField: [],
      filterList: [],
      sortBy: '-datetimeLocal'
    };
  };

  function setupParametersOnFilterChange(newFilter, parameters) {

    var { filterList, filterField } = processFilters(newFilter);
    parameters.filters = filterList || [];
    parameters.filterField = filterField || [];
    return parameters;
  }



  return service;
}]);


