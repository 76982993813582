angular.module('sharedServices').service('$pay', ['$http', '$rootScope', '$localStorage', function ($http, $rootScope, $localStorage) {
  this.getAuthorizationHeader = function () {
    const config = $localStorage.config();
    return config && config.secret ? config.secret : '';
  };

  this.getUserTrace = function () {
    const config = $localStorage.config();
    return config && config.user ? 'User ' + config.user : '';
  };

  this.headers = {
    'authorization': this.getAuthorizationHeader(),
    'content-type': 'application/json',
    'user-agent': 'datacap virtual terminal'
  };

  this.processSale = function (payload, callback, suppressNotification) {
    this.processTransaction('/pay/credit/sale/', payload, callback, suppressNotification, 'Sale');
  };

  this.processVoid = function (payload, callback, suppressNotification) {
    this.processTransaction('/pay/credit/void', payload, callback, suppressNotification, 'Sale');
  };

  this.authCheck = function (payload, callback, suppressNotification) {
    this.processTransaction('/pay/credit/authonly', payload, callback, suppressNotification);
  };

  this.processReturn = function (payload, callback, suppressNotification) {
    this.processTransaction('/pay/credit/return', payload, callback, suppressNotification, 'Return');
  };

  this.processTransaction = function (url, payload, callback, suppressNotification, tranType) {
    this.headers.Authorization = this.getAuthorizationHeader();
    $rootScope.showProgress = true;
    payload.Trace = this.getUserTrace();

    $http({
      method: 'POST',
      url: url,
      data: JSON.stringify([payload]),
      headers: this.headers
    }).then(
      (response) => this.handleResponse(response, callback, suppressNotification, tranType, true),
      (response) => this.handleResponse(response, callback, suppressNotification, tranType, false)
    );
  };

  this.handleResponse = function (response, callback, suppressNotification, tranType, isSuccess) {
    console.log("returned response from pay api", response)
    const { data } = response;
    const { Status = isSuccess ? "Success" : "ERROR", Account = "NoAccount", Message = isSuccess ? "" : "Server Error. Please try again." } = data || {};
    const formattedMsg = `${Status} (${Account}): ${Message}`;

    $rootScope.showProgress = false;

    if (!isSuccess && !suppressNotification) {
      $rootScope.showError(formattedMsg);
    } else if (isSuccess && !suppressNotification) {
      $rootScope.showSuccess(formattedMsg);
    }

    if (callback) {
      if (tranType) data.tranType = tranType;
      data.processedDate = Date.now();
      callback({ content: data, isSuccessful: isSuccess, formattedMsg: isSuccess ? "" : formattedMsg });
    }
  };
}]);
