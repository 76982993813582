import * as angular from 'angular';

angular.module('reportApp').component('toggleCheckboxSlider', {
  bindings: {
    toggleBatchSync: '&'
  },
  template: `
    <label class="switch">
      <input type="checkbox" ng-model="$root.showSyncBatch" ng-click="$root.toggleBatchSync()">
      <span class="slider round"></span>
    </label>
  `,
  controller: ['$rootScope', function(this: any, $rootScope) {

    
  }]
});