'use strict';
import { reportApp } from '../Report/reportApp';

reportApp.service('$reportService', ['$rootScope', '$location', '$http', function ($rootScope, $location, $http) {


    this.buildRequest = function (url, query) {

        var port = $location.port() === 80 ? "" : ":" + $location.port();

        url = $location.protocol() + "://" + $location.host() + port + "/Reports/Proxy" + url;
        var request = {
            url: url,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params: query
        };

        return request;
    };


    this.buildResponseHandler = function (callback, wasSuccessful) {

        return function (response) {

            var data = response.data || {};
            var status = data.Status || "ERROR";
            var errorResult = {};
            if (data.result) {
                errorResult = JSON.parse(data.result);
            }
            var message = data.Message || errorResult.Message || "Server Error. Please try again.";
            var errors = {};
            if (response.data && response.data.ModelState) {
                for (var modelField in response.data.ModelState) {
                    if (!response.data.ModelState.hasOwnProperty(modelField)) continue;
                    var field = modelField.replace("model.", "");
                    errors[field] = response.data.ModelState[modelField];
                }
            }
            var result = {
                wasSuccessful: wasSuccessful,
                content: response.data,
                message: message,
                errors: errors,
                statusCode: response.status
            };

            if (callback) {
                callback(result);
            }

        };
    };

    this.buildUrlWithParameters = (parameters, injectedUrl, query, useSync, startTime) => {


        useSync ? injectedUrl = addSyncValues(injectedUrl, parameters, startTime) : null;

        for (var parameter in parameters) {
            if(parameter === 'startTime' && !useSync){
                continue;
            }
            if (parameters.hasOwnProperty(parameter)) {
              var value = parameters[parameter];

              // If the property name contains the word "date", format the value as a UTC date string
              if (parameter.toLowerCase().indexOf('date') >= 0) {
                value = moment.utc(value).format('YYYY-MM-DD');
              }

              // If the property name is found in the `injectedUrl` string, replace the parameter placeholder with the value
              (injectedUrl.indexOf(parameter) >= 0) ? injectedUrl = injectedUrl.replace("{" + parameter + "}", value) : query[parameter] = value;
            }
        }


        return [injectedUrl, query];
    }

    const isValidUrl = (urlString) => {
        try {
            return Boolean(new URL(urlString));
        }
        catch(e){
            return false;
        }
    }

    const handleOrigin = (injectedUrl, parameters, startTime) => {
        try {

            // Remove the part of the url that should be after the Synced in the eventual URL
            var afterSync = injectedUrl.split('/').slice(3);
            afterSync = afterSync.at(-1)
            afterSync += `/${startTime}`;

            // Attempt to cast the URL to remove the pathname
            var tempURL = new URL(injectedUrl);
            var injectedUrlSplit = tempURL.pathname.split('/').slice(0, -1);

            // This actually does the insert, BUT, since the URL will also encode the query string after the inserted SYNC,
            // we need to add that back in
            injectedUrlSplit.splice(3, 0, 'Synced');
            injectedUrlSplit.slice(0, -1)

            // Join the modified URL back in and add start time to the parameters (for use in the custom encoding function)
            injectedUrl = injectedUrlSplit.join('/');

            // Add the query string back in
            injectedUrl = tempURL.origin + injectedUrl + '/' + afterSync;
            return injectedUrl;
        } catch (error) {

            return '';
        }

    };

    function addSyncValues(injectedUrl, parameters, startTime) {

        // If the injectedURL has an origin, https and a domain name for instance, it needs different handling
        if(isValidUrl(injectedUrl)){
            return handleOrigin(injectedUrl, parameters, startTime);
        }

        // No origin, so we can just split the url and add the Synced folder
        var injectedUrlSplit = injectedUrl.split('/');
        injectedUrlSplit.splice(3, 0, 'Synced');
        injectedUrl = injectedUrlSplit.join('/');
        injectedUrl += `/${startTime}`;
        //
        // parameters['startTime'] = startTime.length > 5 ? startTime.slice(0, -2).trim() : startTime.trim();
        return injectedUrl;
    }


  this.getData = function (url, parameters, startTime, useSync, callback) {

        var injectedUrl = url;

        var query = {};
        // Call the `buildUrlWithParameters` function to replace any URL parameters in the `injectedUrl` string with their corresponding values from the `parameters` object
        var result = this.buildUrlWithParameters(parameters, injectedUrl, query, useSync, startTime);

        // Update the `injectedUrl` and `query` variables with the modified values returned by the `buildUrlWithParameters` function
        injectedUrl = result[0];
        query = result[1];

        // Find any URL parameters that were not replaced in the `injectedUrl` string
        var unReplaceFields = injectedUrl.match(/\{([^{]*)\}/g);

        // Missing field check
        if (unReplaceFields !== null) {

            // If there are any unreplaced URL parameters, create an error response object with a message indicating which fields are missing
            var requiredFields = unReplaceFields.map(function (field) {
                return field.replace(/([A-Z])/g, ' $1')
                    .replace('{', '')
                    .replace('}', '')
                    .replace(/^./, function (s) { return s.toUpperCase(); });
            });

            var result = {
                wasSuccessful: false,
                content: null,
                message: "Missing required fields: " + requiredFields.join(', '),
                errors: {},
                statusCode: 400
            };

            if (callback) {
                callback(result);
            }

            return;
        }

        // If there are no unreplaced URL parameters, make an HTTP request to the `injectedUrl` with the `query` parameters
        $http(this.buildRequest(injectedUrl, query))
            .then(
                this.buildResponseHandler(callback, true), this.buildResponseHandler(callback, false)
            )
                .catch((error) => {
                    this.buildResponseHandler(callback, false)
                });
    };
}]);




